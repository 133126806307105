export function checkOpenaiApiKey(str: string) {
    var pattern = /^.+$/; // 正则表达式匹配任何非空白字符序列
    return pattern.test(str);
}

export function checkOpenaiApiKeys(str: string) {
    if (str.includes(",")) {
        const userApiKeys = str.split(",");
        return userApiKeys.every(checkOpenaiApiKey);
    }

    return checkOpenaiApiKey(str);
}
